<template>
  <div class="sales-assistant-portal-index">
    <router-view></router-view>
    <van-tabbar
      :value="active"
      placeholder
      active-color="#B9921A"
      @change="onChange"
    >
      <van-tabbar-item name="salesAssistantPortalHome">
        <span>{{ $t("首页") }}</span>
        <template #icon="props">
          <img :src="props.active ? homeIconActive : homeIcon" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="salesAssistantPortalUser">
        {{ $t("用户") }}
        <template #icon="props">
          <img :src="props.active ? userIconActive : userIcon" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Programme from './components/Programme.vue'
import CommmonFunction from './components/CommmonFunction.vue'
import PerformanceRank from './components/PerformanceRank.vue'
import ToDo from './components/ToDo.vue'
import homeIcon from '@/images/home_icon.png'
import userIcon from '@/images/user_icon.png'
import homeIconActive from '@/images/home_icon_active.png'
import userIconActive from '@/images/user_icon_active.png'
import dayjs from 'dayjs'
export default {
  name: 'sales-assistant-portal',
  components: {
    Programme,
    CommmonFunction,
    ToDo,
    PerformanceRank,
  },
  data() {
    return {
      active: ['salesAssistantPortalHome', 'salesAssistantPortalUser'].includes(
        this.$route.name
      )
        ? this.$route.name
        : 'salesAssistantPortalHome',
      homeIcon,
      userIcon,
      userIconActive,
      homeIconActive,
      showPopover: false,
      toDoTime: '',
      rolesInfo: {},
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    showRank() {
      return true
    },
  },
  mounted() {
    this.onRefresh()
  },
  beforeDestroy() {
    clearInterval(this.timerId)
  },
  methods: {
    onChange(name) {
      this.activeTabVal = 0
      this.$router
        .push({
          name: `${name}`,
          query: {
            replace: true,
          },
        })
        .then(() => {
          this.$nextTick(() => {
            this.active = name
          })
        })
    },
    onRefresh() {
      this.refreshTime = dayjs().format('HH:mm')
      this.isLoading = true
      this.key = String(Math.ceil(Math.random() * 10000))
      this.isLoading = false
    },
  },
}
</script>
<style lang="less" scoped></style>
